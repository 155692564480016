import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { getBlogPostMeta } from '../utils/helpers';
import { PAGE_TYPE, PAGE_PREFIX } from '../utils/constants';
import BlogProxy from '../components/proxy/blog-blocks';

const BlogPostTemplate = props => {
  const { site, post, markdownRemark } = props.data;

  const seo = getBlogPostMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
    post,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.BlogPost}
      pagePrefix={PAGE_PREFIX.Blog}
      proxy={BlogProxy}
      {...props}
    />
  );
};

export default BlogPostTemplate;

export const blogPostPageQuery = graphql`
  query BlogPostPageQuery($slug: String!) {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    post: blogPost(slug: { eq: $slug }) {
      id
      primary_author {
        name
        profile_image
      }
      authors {
        name
        profile_image
      }
      tags {
        name
        slug
        visibility
      }
      primary_tag {
        name
      }
      title
      slug
      feature_image
      featured
      visibility
      url
      excerpt
      html
      published_at
      updated_at
      meta_title
      meta_description
    }
    markdownRemark(fields: { slug: { eq: "/blog-post/" } }) {
      ...PageMarkdownFragment
    }
    ...ConfigFragment
  }
`;
